import React from 'react'
import Navigation from './Navigation'
import {Link} from 'react-router-dom'

function Header(){
    return (
        <header className="border-b p-3 flex justify-between items-center">
            <Link to="/" className="font-bold">
                <img className="img-responsive" src={require("../Images/Frostyz logo-07.jpg")} width="70" height="70" alt="Frostyz Lounge" /> Frostyz Lounge, Wine and Bar
            </Link>

            <Navigation />
        </header>
    )
}

export default Header