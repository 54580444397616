/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { ChevronLeftIcon } from '@heroicons/react/solid'

export default function Pagination({updatePage, totalEntries, nextPage, prevPage,
                                     totalPages, perPage, currentPage}) {

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        {prevPage ? <button
            disabled={prevPage === null}
            onClick={() => updatePage(prevPage)}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
        >
          Previous
        </button> : ''}
        {nextPage ? <button
            onClick={() => updatePage(nextPage)}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
        >
          Next
        </button> : ''}
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">1</span> to <span className="font-medium">{perPage}</span> of{' '}
            <span className="font-medium">{totalEntries}</span> results
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
                disabled={prevPage === null}
                onClick={() => updatePage(prevPage)}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {[...Array(totalPages)].map((x, i) => (
              ( (i+1) % 5 === 0) ?
                <span key={i} className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  ...
                </span>
              :
                <button
                    key={i}
                    onClick={() => {
                      localStorage.setItem('page', i+1);
                      const endIndex = currentPage.indexOf("PageNumber");
                      const prefix = currentPage.substring(0, endIndex);
                      const newString = prefix + `PageNumber=${i+1}&PageSize=${perPage}`;
                      updatePage(newString);
                    }}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  { i+1 }
                </button>
              )
            )}

          </nav>
        </div>
      </div>
    </div>
  )
}
