import React, {useEffect, useState} from 'react'
import Loader from '../Components/Loader'
import ProductCard from '../Components/ProductCard'
import axios from 'axios'

import FilterList from '../Components/FilterList'
import Pagination from '../Components/Pagination'

function Home(){
    // Create your own Mock API: https://mockapi.io/
    const [request, setRequest] = useState({
        loading: true,
        data: null,
        error: false
    });
    const [currentPage, setCurrentPage] = useState();
    const [nextPage, setNextPage] = useState();
    const [prevPage, setPrevPage] = useState();
    const [totalPages, setTotalPages] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);
    localStorage.removeItem('page');

    let categoryId = '2ba7077c-bde6-4c8c-9d3b-0cc274739adb';

    let page = 1;
    localStorage.setItem('page', page);

    let url = `https://frostyzapi.azurewebsites.net/api/v1/Category/${categoryId}?PageNumber=${page}&PageSize=20`

    let content = null;


    useEffect(() => {
        axios.get(url)
            .then(response => {
                setRequest({
                    loading: false,
                    data: response.data.data.productsList,
                    error: false
                });
                setCurrentPage(response.data.meta.pagination.currentPage);
                setNextPage(response.data.meta.pagination.nextPage);
                setPrevPage(response.data.meta.pagination.previousPage);
                setTotalPages(response.data.meta.pagination.totalPages);
                setPerPage(response.data.meta.pagination.perPage);
                setTotalEntries(response.data.meta.pagination.totalEntries);
            })
            .catch(() => {
                setRequest({
                    loading: false,
                    data: null,
                    error: true
                })
            })
    }, [url]);

    const setCategoryId = (val) => {
        categoryId = val;
        getPageData(1)
    }

    const getPageData = async (val) => {
        if (typeof categoryId === 'object' && categoryId !== null) {
            page = +localStorage.getItem('page');
            url = `https://frostyzapi.azurewebsites.net/api/v1/Category/${categoryId.id}?PageNumber=${page}&PageSize=20`;
        }
        else {
            page = +localStorage.getItem('page');
            url = `https://frostyzapi.azurewebsites.net/api/v1/Category/${categoryId}?PageNumber=${page}&PageSize=20`;
        }

        if (typeof val === 'string' && val !== null) {
            url = val;
        }

        await axios.get(url)
            .then(response => {
                setRequest({
                    loading: false,
                    data: response.data.data.productsList,
                    error: false
                });
                setCurrentPage(response.data.meta.pagination.currentPage);
                setNextPage(response.data.meta.pagination.nextPage);
                setPrevPage(response.data.meta.pagination.previousPage);
                setTotalPages(response.data.meta.pagination.totalPages);
                setPerPage(response.data.meta.pagination.perPage);
                setTotalEntries(response.data.meta.pagination.totalEntries);
            })
            .catch(() => {
                setRequest({
                    loading: false,
                    data: null,
                    error: true
                })
            });

        // scroll to top of page after loading data
        window.scrollTo(0, 0);
    }

    if(request.error){
        content = <div>
            <div className="bg-blue-300 mb-2 p-3">
                If you see this error. Please remember to create your own <a href="https://mockapi.io/">mock API</a>.
            </div>
            <div className="bg-red-300 p-3">
                There was an error please refresh or try again later.
            </div>
        </div>
    }

    if(request.loading){
        content = <Loader></Loader>
    }

    if(request.data){
        if (!request.data) {
            content = 
            request.data.map((product) =>
                <div key={product.id} className="flex-no-shrink w-full sm:w-1/4 md:px-3">
                    <ProductCard 
                        product={product}
                    />
                </div>
            )
        }
        else {
            content = 
            request.data.map((product) =>
                <div key={product.id} className="flex-no-shrink w-full sm:w-1/4 md:px-3">
                    <ProductCard 
                        product={product}
                    />
                </div>
            );
        }
    }

    return (
        <div className="container mx-auto">
            <h1 className="font-bold text-2xl mb-3">
                Menu List
            </h1>
            <FilterList cat={setCategoryId} />
            <br />
            <div className="md:flex flex-wrap md:-mx-3">
                { content } 
            </div>

            <Pagination updatePage={getPageData} totalEntries={totalEntries}
                        nextPage={nextPage} prevPage={prevPage} totalPages={totalPages}
                        perPage={perPage} currentPage={currentPage} />
        </div>
    )
}

export default Home