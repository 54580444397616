import React from 'react'
import { Link } from 'react-router-dom'

function ProductCard(props) {
    //const [productIds, setProductIds] = useState(['']);

    // const formatter = new Intl.NumberFormat('en-NG', {
    //     style: 'currency',
    //     currency: 'NGN',
    //     minimumFractionDigits: 2
    // })

    // const addProductsToOrder = ()  => {
    //     const id = props.product.id;
    //
    //     const ids = localStorage.getItem('orderProducts');
    //
    //     if (ids) {
    //         localStorage.setItem('orderProducts', ids + "," + id);
    //     }
    //     else {
    //         localStorage.setItem('orderProducts', id);
    //     }
    // }

    // const removeProductsFromOrder = ()  => {
    //     const id = props.product.id;
    //     const ids = localStorage.getItem('orderProducts').split(",");
    //     const i = ids.indexOf(id);
    //     ids.splice(i, 1);
    //
    //     localStorage.setItem('orderProducts', ids.join(","));
    // }

    const imageUrl = props.product.imageName === null
        ? 'https://res.cloudinary.com/peteroye/image/upload/v1622105422/placeholder_qsza9b.png'
        : props.product.imageName;

    return (
        <div className="border mb-4 rounded overflow-hidden">
                <div
                    style={{
                        'backgroundImage': `url('${imageUrl}')`,
                        'backgroundRepeat': 'no-repeat',
                        'backgroundSize': 'contain',
                        'backgroundPosition': 'center'
                    }}
                    className="w-full h-40 bg-blue bg-cover"
                >
                </div>
            <div className="p-3">
                <h3 className="font-bold text-xl mb-3">
                    <Link to={`/products/${props.product.id}`}>
                        { props.product.productName }
                    </Link>    
                </h3>
                <div className="font-bold mb-3">
                    { (props.product.unitPrice).toLocaleString('en-NG', {
                        style: 'currency',
                        currency: 'NGN'
                }) }
                </div>
                <div className="mb-0.5">
                    {props.product.description}
                </div>

                {/*<Link */}
                {/*    to={`/products/${props.product.id}`}*/}
                {/*    className="bg-blue-500 text-white p-2 flex justify-center w-full"*/}
                {/*>*/}
                {/*    Add to Order*/}
                {/*</Link>*/}
            </div>
        </div>
    )
}

export default ProductCard
