/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment, useEffect, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, SelectorIcon} from '@heroicons/react/solid'
import axios from "axios";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const optionItems = [
    {
        id: "2ba7077c-bde6-4c8c-9d3b-0cc274739adb",
        categoryName: "Regular Drink Menu",
        description: "Regular Drink Menu",
        productsList: null,
        createdAt: "2021-05-17T09:22:36.7733333",
        updatedAt: null
    }
]
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function FilterList({cat}) {
    const [categories, setCategories] = useState([{
        id: '',
        categoryName: ''
    }]);
    const [selected, setSelected] = useState(optionItems[0]);

    const setCat = (val) => {
        setSelected(val);
        cat(val);
    }

    const reqOptions = {
        method: 'get',
        url: 'https://frostyzapi.azurewebsites.net/api/v1.0/Category?categoryName=regular',
        timeout: 600000
    }

    const fetchData = async () => {
        await axios(reqOptions)
            .then(res => {
                if (res.data.data) {
                    toast.success(`${res.data.message}`);
                    setCategories(res.data.data);
                    setCat(res.data.data[0]);
                }
            })
            .catch(err => {
                if (err.response) {
                    toast.error(`${err.response.data.Message}`);
                } else {
                    toast.error('Failed to retrieve items.')
                }
            });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <ToastContainer/>
            <Listbox value={selected} onChange={setCat}>
                {({open}) => (
                    <>
                        <Listbox.Label className="block text-sm font-medium text-gray-700">Filter By
                            Category: </Listbox.Label>
                        <div className="mt-1 relative">
                            <Listbox.Button
                                className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              <span className="flex items-center">
                                  <span className="ml-3 block truncate">{selected.categoryName}</span>
                              </span>
                                <span
                                    className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                              </span>
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options
                                    static
                                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                    {categories.map((category) => (
                                        <Listbox.Option
                                            key={category.id}
                                            className={({active}) =>
                                                classNames(
                                                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                                )
                                            }
                                            value={category}
                                        >
                                            {({selected, active}) => (
                                                <>
                                                    <div className="flex items-center">
                                                        {/*<img src={person.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />*/}
                                                        <span
                                                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                        >
                                                        {category.categoryName}
                                                      </span>
                                                    </div>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-white' : 'text-indigo-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                      </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        </>
    )
}
